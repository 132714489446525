.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px;
  }
  
  .home-container h1 {
    font-size: 48px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .home-container p {
    font-size: 20px;
    color: #666;
    max-width: 700px;
    text-align: center;
    line-height: 1.6;
  }
  
  .home-container a {
    display: inline-block;
    margin-top: 20px;
    font-size: 18px;
    color: white;
    background-color: #007bff;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s;
  }
  
  .home-container a:hover {
    background-color: #0056b3;
  }
  