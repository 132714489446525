/* General Header Styling */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  padding: 10px 20px;
  color: white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Add padding to the body to prevent content from going under the header */
body {
  padding-top: 60px;
}

/* Title Styling */
.header .title a {
  color: white;
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;
}

/* Navigation Links */
.nav-links {
  display: flex;
  gap: 20px;
}

/* Make the nav-links hidden on mobile initially */
.nav-links.open {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 18px;
}

.nav-links a:hover {
  color: #f5f5f5;
}

/* Hamburger Icon Styling */
.hamburger {
  display: none;
  font-size: 30px;
  cursor: pointer;
}

/* Logout Button Styling */
.logout-btn {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.logout-btn:hover {
  background-color: #e53935;
}

/* Media query for smaller screens (e.g., max-width: 768px) */
@media (max-width: 768px) {
  .nav-links {
    display: none; /* Hide nav links on mobile */
    flex-direction: column;
    gap: 10px;
    position: absolute;
    top: 50px;
    right: 20px;
    background-color: #333;
    width: 100%;
    padding: 20px;
  }

  /* Display the hamburger menu on smaller screens */
  .hamburger {
    display: block;
  }

  .nav-links.open {
    display: flex; /* Show nav links when menu is open */
  }
}
