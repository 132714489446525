.about-container {
    padding: 40px;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }
  
  .about-container h2 {
    font-size: 36px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .about-container p {
    font-size: 18px;
    line-height: 1.6;
    color: #555;
    margin-bottom: 20px;
  }
  
  .about-container a {
    color: #007bff;
    text-decoration: none;
  }
  
  .about-container a:hover {
    text-decoration: underline;
  }
  